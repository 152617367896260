<template>
  <div>
    <a-spin :spinning="loading">
      <a-carousel>
        <div>
          <img
            src="@/assets/product/banner-1.jpg"
            style="height: 100%; width: 100%; -webkit-user-drag: none"
            alt=""
          />
        </div>
      </a-carousel>

      <!-- 产品列表 -->
      <div class="info-content">
        <a-card :bordered="false">
          <template slot="title">
            <h2>{{ $t('message')['home.productCenter'] }}</h2>
          </template>

          <a-row type="flex">
            <a-col
              :span="5"
              :offset="1"
              v-for="item in productList"
              :key="item.id"
            >
              <router-link
                :to="{
                  path: '/product/small-module/detail/' + item.id,
                  query: { locale: $i18n.locale },
                }"
              >
                <a-card :bordered="false" :bodyStyle="{ padding: '0px' }">
                  <img
                    class="product-img"
                    :alt="$i18n.locale === 'enUS' ? item.nameEn : item.name"
                    :src="
                      $i18n.locale === 'enUS' ? item.imageUrlEn : item.imageUrl
                    "
                  />
                  <p
                    style="text-align: center; margin-top: 15px; color: #333333"
                  >
                    {{ $i18n.locale === 'enUS' ? item.nameEn : item.name }}
                  </p>
                </a-card>
              </router-link>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
import { getProductList } from '@/api/product'
export default {
  name: 'ProductList',
  data() {
    return {
      productList: [],
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 1000,
      },
    }
  },
  created() {
    this.getProductList()
  },
  methods: {
    // 获取产品列表
    getProductList() {
      this.loading = true
      getProductList(this.params)
        .then((res) => {
          if (res.code === 200) {
            this.productList = res.rows
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(err.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 250px; */
  /* line-height: 250px; */
  background: #364d79;
  overflow: hidden;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}

.info-content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}

.content-text {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}

.product-img {
  width: 100%;
  max-width: 100% !important;
}

.product-img:hover {
  transform: scale(1.1, 1.1);
}
</style>
